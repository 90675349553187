<template>
  <div class="workbench w">
    <div class="left_menu_box">
      <div class="work_bench_title">
        工作台
      </div>
      <div class="area-title">个人服务</div>
      <div class="top_my_resume_box">
        <div class="fn-sz-15 fn-cl-333333 mar-bottom-13">我的简历</div>
        <div class="flex-align-center">
          <div class="top_menu_item">
            <div class="inner_menu_list">
              <div class="inner_menu_item" v-for="item in ep_top_menu_list" :key="item.link" @click="goLink(item.link)">
                <div class="inner_menu_icon">
                  <img :src="item.icon" />
                </div>
                <div :class="['inner_menu_text',item.link==curRouter?'active_color':'']">{{ item.cMenuName }}</div>
              </div>
            </div>
          </div>
          <div class="send_or_get_num">
            <div class="send_item_num">
              <div class="send_item_num_text">{{ $store.state.userInfo.send_num }}</div>
              <div class="send_item_name_text">已投递</div>
            </div>
            <div class="send_item_num">
              <div class="send_item_num_text">{{ $store.state.userInfo.read_num }}</div>
              <div class="send_item_name_text">已被查阅</div>
            </div>
          </div>
        </div>
      </div>
      <div class="area-title">企业服务</div>
      <div class="ep_menu_list">
        <div class="ep_menu_item" v-for="(menuItem, index) in ep_menu_list" :key="index">
          <div class="inner_ep_menu_item_title">{{ menuItem.name }}</div>
          <div class="inner_menu_list">
            <div class="inner_menu_item" v-for="(childItem, cIndex) in menuItem.childMenu" :key="cIndex" @click="goLink(childItem.link)">
              <div class="inner_menu_icon">
                <img :src="childItem.icon" />
              </div>
              <div :class="['inner_menu_text',childItem.link==curRouter?'active_color':'']">{{ childItem.cMenuName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right_content_box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      curRouter: '/workbench/myIntroduce',
      ep_top_menu_list: [
        { cMenuName: '我的简历', link: '/workbench/myIntroduce', icon: require('@/assets/img/icon/icon_my_resume.png') },
        { cMenuName: '我的投递', link: '/workbench/myDeliver', icon: require('@/assets/img/icon/icon_my_deliver.png') },
      ],
      ep_menu_list: [
        {
          name: '招/中标管理',
          childMenu: [
            { cMenuName: '发布招/中标', link: '/workbench/publishBids', icon: require('@/assets/img/workBench/发布招中标.png') },
            { cMenuName: '我的招/中标', link: '/workbench/myBids', icon: require('@/assets/img/workBench/我的招中标.png') }
          ]
        },
        {
          name: '招工管理',
          childMenu: [
            { cMenuName: '发布招工', link: '/workbench/publishRecruitWork', icon: require('@/assets/img/workBench/发布招工.png') },
            { cMenuName: '我的招工', link: '/workbench/myRecruitWork', icon: require('@/assets/img/workBench/我的招工.png') }
          ]
        },
        {
          name: '分包管理',
          childMenu: [
            { cMenuName: '发布分包', link: '/workbench/publishSubcontract', icon: require('@/assets/img/workBench/发布分包.png') },
            { cMenuName: '我的分包', link: '/workbench/mySubcontract', icon: require('@/assets/img/workBench/我的分包.png') }
          ]
        },
        {
          name: '招采管理',
          childMenu: [
            { cMenuName: '发布招采', link: '/workbench/publishPurchase', icon: require('@/assets/img/workBench/发布招采.png') },
            { cMenuName: '我的招采', link: '/workbench/myPurchase', icon: require('@/assets/img/workBench/我的招采.png') }
          ]
        },
        {
          name: '机械管理',
          childMenu: [
            { cMenuName: '发布机械租售', link: '/workbench/publishMachine', icon: require('@/assets/img/workBench/发布机械租售.png') },
            { cMenuName: '我的机械', link: '/workbench/myMachine', icon: require('@/assets/img/workBench/我的机械.png') }
          ]
        },
        {
          name: '产品管理',
          childMenu: [
            { cMenuName: '发布产品', link: '/workbench/publishProduct', icon: require('@/assets/img/workBench/发布产品.png') },
            { cMenuName: '我的产品', link: '/workbench/myProduct', icon: require('@/assets/img/workBench/我的产品.png') }
          ]
        }
      ]
    };
  },
  methods: {
    goLink (url) {
      this.$router.push(url);
      this.changeCur(url);
    },
    changeCur (path) {
      this.curRouter = path;
    }
  },
  mounted () {
    this.changeCur(this.$route.path);
  },
  watch: {
    $route (val) {
      this.changeCur(val.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.workbench {
  padding-top: 26px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px !important;
  .left_menu_box {
    width: 392px;
    height: 719px;
    box-sizing: border-box;
    padding: 37px 25px 0 17px;
    border-radius: 10px;
    background: linear-gradient(180deg, #3f63ff 3%, #6ea2f7 49%, #3a7afa 99%);
    box-sizing: border-box;
    .work_bench_title {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      margin-bottom: 40px;
    }
  }

  .top_my_resume_box {
    background: linear-gradient(180deg, #c4d9fa 0%, #ffffff 100%);
    border-radius: 10px;
    border: 1px solid #d0e5f9;
    padding: 20px 26px;
    box-sizing: border-box;
    margin-bottom: 14px;
    .send_or_get_num {
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      border-left: 1px solid #ffffff;
      flex: none;
      padding-left: 21px;
      .send_item_num {
        text-align: center;
        .send_item_num_text {
          margin-bottom: 7px;
          font-size: 24px;
          font-weight: 700;
          color: #333333;
        }
        .send_item_name_text {
          font-size: 11px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .area-title {
    color: #fff;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .top_menu_item {
    width: 50%;
    flex: none;
    box-sizing: border-box;
    border-radius: 10px;
    padding-right: 21px;
    .inner_menu_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;
      .inner_menu_item {
        cursor: pointer;
        .inner_menu_icon {
          width: 38px;
          height: 38px;
          margin: 0 auto;
          margin-bottom: 5px;
          img {
            width: 38px;
            height: 38px;
          }
        }
        .inner_menu_text {
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .ep_menu_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .ep_menu_item {
      margin-bottom: 10px;
    }
  }
  .ep_menu_item {
    width: 170px;
    height: 110px;
    box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #ffffff;
    background-color: #fff;
    .inner_ep_menu_item_title {
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      padding: 11px 16px 14px;
    }
    .inner_menu_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      .inner_menu_item {
        width: 50%;
        cursor: pointer;
        .inner_menu_icon {
          width: 38px;
          height: 38px;
          margin: 0 auto;
          margin-bottom: 4px;
          img {
            width: 38px;
            height: 38px;
          }
        }
        .inner_menu_text {
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .right_content_box {
    width: 790px;
    background-color: #fff;
    border-radius: 8px 8px 8px 8px;
  }
}
</style>
